<template>
	<div class="modal-overlay">
		<div
			id="results-modal"
			class="modal"
			:style="
				isMobile
					? `width: inherit; ${isHorizontalOrientation ? 'position: absolute !important' : ''}`
					: 'min-width: 656px; max-width: 656px;'
			"
			@click.right.prevent
		>
			<div class="modal-title-bar">
				<img src="@/assets/svg/Chemformation_logo_Horz.svg" height="25" style="padding: 3px" />
				<button type="button" @click="$emit('close')">
					<v-icon>mdi-close-thick</v-icon>
				</button>
			</div>

			<div class="modal-content">
				<div class="modal-content-nav">
					<div
						class="modal-content-nav-tab"
						:class="activeTab == 1 ? 'modal-content-nav-tab-active' : null"
						@click="activeTab = 1"
					>
						Product Information
					</div>
					<div
						v-show="featuresBenefits.length > 0"
						class="modal-content-nav-tab"
						:class="activeTab == 2 ? 'modal-content-nav-tab-active' : null"
						@click="
							activeTab = 2;
							$emit('logActivity', {
								productId: productId,
								event: 'FeaturesAndBenefitsTabClicked',
								context: productInformation.productName,
								area: 'ProductModal',
							});
						"
					>
						Features &amp; Benefits
					</div>
					<div
						v-show="uses.length > 0"
						class="modal-content-nav-tab"
						:class="activeTab == 3 ? 'modal-content-nav-tab-active' : null"
						@click="
							activeTab = 3;
							$emit('logActivity', {
								productId: productId,
								event: 'UsesTabClicked',
								context: productInformation.productName,
								area: 'ProductModal',
							});
						"
					>
						Uses
					</div>
					<div
						v-show="formulations.length > 0"
						class="modal-content-nav-tab"
						:class="activeTab == 4 ? 'modal-content-nav-tab-active' : null"
						@click="
							activeTab = 4;
							$emit('logActivity', {
								productId: productId,
								event: 'FormulationsTabClicked',
								context: productInformation.productName,
								area: 'ProductModal',
							});
						"
					>
						Formulations
					</div>
					<div
						v-show="requestSample.isPartner === 'True' && productInformation.requestSampleLink"
						class="modal-content-nav-tab"
						:class="activeTab == 5 ? 'modal-content-nav-tab-active' : null"
						@click="
							activeTab = 5;
							$emit('logActivity', {
								productId: productId,
								event: 'RequestSampleClicked',
								context: productInformation.productName,
								area: 'ProductModal',
							});
						"
					>
						Request Sample
					</div>
					<div
						class="modal-content-nav-tab"
						:class="activeTab == 6 ? 'modal-content-nav-tab-active' : null"
						@click="
							activeTab = 6;
							$emit('logActivity', {
								productId: productId,
								event: 'BillOfLadingClicked',
								context: productInformation.productName,
								area: 'ProductModal',
							});
						"
					>
						Bill of Lading
					</div>
				</div>
				<div
					v-show="activeTab == 1"
					id="product-information"
					class="modal-content-details"
					style="padding: 1rem 0.5rem"
				>
					<img v-if="vendorLogo !== ''" id="vendorLogo" :src="vendorLogo" />
					<div v-if="productInformation.productName" class="dialog-f">
						<div class="dialog-fl">Product Name</div>
						<div class="dialog-fv">{{ productInformation.productName }}</div>
					</div>
					<div v-if="productInformation.grades && productInformation.grades.length > 0" class="dialog-f">
						<div class="dialog-fl">Grade</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(g, i) in productInformation.grades" :key="i">{{ g }}</li>
							</ul>
						</div>
					</div>
					<div
						v-if="
							productInformation.remarks &&
							productInformation.remarks.length > 0 &&
							productInformation.remarks.trim().length > 0
						"
						class="dialog-f"
					>
						<div class="dialog-fl">Remarks</div>
						<div class="dialog-fv">{{ productInformation.remarks }}</div>
					</div>
					<div v-if="productInformation.vendor" class="dialog-f">
						<div class="dialog-fl">Vendor</div>
						<div class="dialog-fv">{{ productInformation.vendor }}</div>
					</div>
					<div
						v-if="
							productInformation.productAttachments &&
							productInformation.productAttachments.length > 0
						"
						class="dialog-f"
					>
						<div class="dialog-fl">Documents</div>
						<div class="dialog-fv">
							<div>
								<template v-for="(docs, i) in productInformation.productAttachments">
									<a
										:key="i"
										target="_blank"
										:href="getDocumentLink(docs.attachment, docs.dropboxId)"
										@click="
											$emit('logActivity', {
												productId: productId,
												event: 'DocumentClicked',
												context: JSON.stringify({
													description: docs.description,
													attachment: getDocumentLink(docs.attachment, docs.dropboxId),
												}),
												area: 'ProductModal',
											})
										"
									>
										{{ docs.description ? docs.description : `Attachment ${i + 1}` }}
									</a>
									<br :key="`${i}-documents`" />
								</template>
							</div>
						</div>
					</div>
					<div
						v-if="
							productInformation.crossReference1 ||
							productInformation.crossReference2 ||
							filteredCrossReferences.length > 0
						"
						class="dialog-f"
					>
						<div class="dialog-fl">Cross Reference</div>
						<div class="dialog-fv">
							<div>
								<ul class="values" v-if="filteredCrossReferences.length > 0">
									<li
										:key="crossRef.crossReferenceId"
										v-for="crossRef in filteredCrossReferences"
									>
										<a
											:href="nav(crossRef.crossReferenceId)"
											target="_blank"
											rel="noopener noreferrer"
											@click="
												$emit('logActivity', {
													productId: productId,
													event: 'CrossReferenceClicked',
													context: JSON.stringify({
														description: crossRef.name,
														crossReference: nav(crossRef.crossReferenceId),
													}),
													area: 'ProductModal',
												})
											"
										>
											{{ crossRef.name }}
										</a>
									</li>
								</ul>
								<template
									v-if="
										productInformation.crossReference1 && filteredCrossReferences.length === 0
									"
								>
									<a
										target="_blank"
										:href="
											getDocumentLink(
												productInformation.crossReference1,
												productInformation.crossReference1DropboxId
											)
										"
										@click="
											$emit('logActivity', {
												productId: productId,
												event: 'CrossReferenceClicked',
												context: JSON.stringify({
													description: productInformation.crossReferenceDescription1,
													crossReference: getDocumentLink(
														productInformation.crossReference1,
														productInformation.crossReference1DropboxId
													),
												}),
												area: 'ProductModal',
											})
										"
									>
										{{
											productInformation.crossReferenceDescription1
												? productInformation.crossReferenceDescription1
												: "Cross Reference 1"
										}}
									</a>
									<br />
								</template>
								<template
									v-if="
										productInformation.crossReference2 &&
										productInformation.crossReference2DropboxId?.length > 0
									"
								>
									<a
										target="_blank"
										:href="
											getDocumentLink(
												productInformation.crossReference2,
												productInformation.crossReference2DropboxId
											)
										"
										@click="
											$emit('logActivity', {
												productId: productId,
												event: 'CrossReferenceClicked',
												context: JSON.stringify({
													description: productInformation.crossReferenceDescription2,
													crossReference: getDocumentLink(
														productInformation.crossReference2,
														productInformation.crossReference2DropboxId
													),
												}),
												area: 'ProductModal',
											})
										"
									>
										{{
											productInformation.crossReferenceDescription2
												? productInformation.crossReferenceDescription2
												: "Cross Reference 2"
										}}
									</a>
								</template>
							</div>
						</div>
					</div>
					<div
						v-if="productInformation.synonyms && productInformation.synonyms.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl">Synonyms</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(synonym, i) in productInformation.synonyms" :key="i">{{ synonym }}</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.formerTradeNames" class="dialog-f">
						<div class="dialog-fl">Former Trade Names</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.formerTradeNames" :key="i">
									{{ text }}
								</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.inci && productInformation.inci.length > 0" class="dialog-f">
						<div class="dialog-fl">INCI</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(inci, i) in productInformation.inci" :key="i">{{ inci }}</li>
							</ul>
						</div>
					</div>
					<div
						v-if="productInformation.productClasses && productInformation.productClasses.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl">Product Classes</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(cls, i) in productInformation.productClasses" :key="i">{{ cls }}</li>
							</ul>
						</div>
					</div>
					<div
						v-if="productInformation.casData && productInformation.casData.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl"></div>
						<div class="dialog-fv">
							<table width="100%" class="small-table">
								<tbody>
									<tr>
										<th align="left" width="23%">CAS #</th>
										<th align="left" width="15%">% Actives</th>
										<th align="left" width="37%">CAS Name</th>
										<th align="left" width="15%">EINECS</th>
									</tr>
									<tr v-for="(data, i) in productInformation.casData" :key="i">
										<td align="left" style="white-space: nowrap">{{ data.casNumber }}</td>
										<td align="left">{{ data.casPercent }}</td>
										<template v-if="Object.keys(data).length">
											<td align="left">
												{{ data.casName }}
											</td>
											<td align="left" style="white-space: nowrap">
												{{ data.einecsNumber }}
											</td>
										</template>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div
						v-if="productInformation.appearances && productInformation.appearances.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl">Appearances</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(appearance, i) in productInformation.appearances" :key="i">
									{{ appearance }}
								</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.odors" class="dialog-f">
						<div class="dialog-fl">Odor</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.odors" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.preservatives" class="dialog-f">
						<div class="dialog-fl">Preservative</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.preservatives" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.solubilities" class="dialog-f">
						<div class="dialog-fl">Solubilities</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.solubilities" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.rsn" class="dialog-f">
						<div class="dialog-fl">Relative Solubility Number</div>
						<div class="dialog-fv">{{ productInformation.rsn }}</div>
					</div>
					<div v-if="productInformation.molesOfEO" class="dialog-f">
						<div class="dialog-fl">Moles of EO</div>
						<div class="dialog-fv">{{ productInformation.molesOfEO }}</div>
					</div>
					<div v-if="productInformation.formulas" class="dialog-f">
						<div class="dialog-fl">Formula</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.formulas" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="productInformation.scs" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv" style="color: green">Safer Choice Screened</div>
					</div>
					<div v-if="productInformation.scil" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv" style="color: green">Safer Chemical Ingredient List</div>
					</div>
					<div v-if="productInformation.specificGravity" class="dialog-f">
						<div class="dialog-fl">Specific Gravity</div>
						<div class="dialog-fv">{{ productInformation.specificGravity }}</div>
					</div>
					<div v-if="productInformation.poundsPerGallon" class="dialog-f">
						<div class="dialog-fl">Pounds Per Gallon</div>
						<div class="dialog-fv">{{ productInformation.poundsPerGallon }}</div>
					</div>
					<div
						v-if="productInformation.bulkDensity && productInformation.bulkDensityUnit"
						class="dialog-f"
					>
						<div class="dialog-fl">Bulk Density</div>
						<div class="dialog-fv">
							<!-- Display lbs/ft³ -->
							<span v-if="productInformation.bulkDensityUnit.startsWith('lbs')">
								{{ productInformation.bulkDensity.toFixed(1) }}
								<template v-if="productInformation.bulkDensityTo">
									to {{ productInformation.bulkDensityTo.toFixed(1) }} lbs/ft³ ({{
										(productInformation.bulkDensity * 16.01846337396).toFixed(1)
									}}
									to {{ (productInformation.bulkDensityTo * 16.01846337396).toFixed(1) }} kg/m³)
								</template>
								<template v-else>
									lbs/ft³ ({{ (productInformation.bulkDensity * 16.01846337396).toFixed(1) }}
									kg/m³)
								</template>
							</span>
							<!-- Display kg/m³ -->
							<span v-else-if="productInformation.bulkDensityUnit.startsWith('kg')">
								{{ (productInformation.bulkDensity * 0.062427960576145).toFixed(1) }}
								<template v-if="productInformation.bulkDensityTo">
									to
									{{ (productInformation.bulkDensityTo * 0.062427960576145).toFixed(1) }} lbs/ft³
									({{ productInformation.bulkDensity.toFixed(1) }} to
									{{ productInformation.bulkDensityTo.toFixed(1) }} kg/m³)
								</template>
								<template v-else>
									lbs/ft³ ({{ productInformation.bulkDensity.toFixed(1) }} kg/m³)
								</template>
							</span>
						</div>
					</div>
					<div v-if="productInformation.meshSize" class="dialog-f">
						<div class="dialog-fl">Mesh Size</div>
						<div class="dialog-fv">{{ productInformation.meshSize }}</div>
					</div>
					<div v-if="productInformation.vaporPressure" class="dialog-f">
						<div class="dialog-fl">Vapor Pressure</div>
						<div class="dialog-fv">{{ productInformation.vaporPressure }}</div>
					</div>
					<div v-if="productInformation.viscosityAt1 || productInformation.viscosity1" class="dialog-f">
						<div class="dialog-fl">Viscosity</div>
						<div class="dialog-fv">
							@ {{ productInformation.viscosityAt1 }}
							<span v-if="productInformation.viscosity1">
								: {{ productInformation.viscosity1 }} {{ productInformation.viscosityUnit1 }}
							</span>
						</div>
					</div>
					<div v-if="productInformation.viscosityAt2 || productInformation.viscosity2" class="dialog-f">
						<div class="dialog-fl">Viscosity (2)</div>
						<div class="dialog-fv">
							@ {{ productInformation.viscosityAt2 }}
							<span v-if="productInformation.viscosity2">
								: {{ productInformation.viscosity2 }} {{ productInformation.viscosityUnit2 }}
							</span>
						</div>
					</div>
					<div v-if="productInformation.viscosityAt3 || productInformation.viscosity3" class="dialog-f">
						<div class="dialog-fl">Viscosity (3)</div>
						<div class="dialog-fv">
							@ {{ productInformation.viscosityAt3 }}
							<span v-if="productInformation.viscosity3">
								: {{ productInformation.viscosity3 }} {{ productInformation.viscosityUnit3 }}
							</span>
						</div>
					</div>
					<div v-if="productInformation.color" class="dialog-f">
						<div class="dialog-fl">Color</div>
						<div class="dialog-fv">
							{{ productInformation.color }} {{ productInformation.colorUnit }}
						</div>
					</div>
					<div v-if="productInformation.evaporationRate" class="dialog-f">
						<div class="dialog-fl">Evaporation Rate</div>
						<div class="dialog-fv">
							{{
								productInformation.evaporationRateQualifier
									? productInformation.evaporationRateQualifier
									: ""
							}}{{ productInformation.evaporationRate }}
							<span style="font-style: italic">(n-Butyl Acetate = 100)</span>
						</div>
					</div>
					<div v-if="productInformation.flashPoint" class="dialog-f">
						<div class="dialog-fl">Flash Point</div>
						<div class="dialog-fv">
							{{
								productInformation.flashPointQualifier
									? productInformation.flashPointQualifier
									: ""
							}}{{ productInformation.flashPoint }}
						</div>
					</div>
					<div v-if="productInformation.boilingPointMin" class="dialog-f">
						<div class="dialog-fl">Boiling Point Min</div>
						<div class="dialog-fv">
							{{
								productInformation.boilingPointMinQualifier
									? productInformation.boilingPointMinQualifier
									: ""
							}}{{ productInformation.boilingPointMin }}
						</div>
					</div>
					<div v-if="productInformation.boilingPointMax" class="dialog-f">
						<div class="dialog-fl">Boiling Point Max</div>
						<div class="dialog-fv">
							{{ productInformation.boilingPointMax }}
						</div>
					</div>
					<div v-if="productInformation.cloudPoint" class="dialog-f">
						<div class="dialog-fl">Cloud Point</div>
						<div class="dialog-fv">
							{{ productInformation.cloudPoint }}
							{{ productInformation.cloudPointTo ? " to " + productInformation.cloudPointTo : "" }}
							{{
								productInformation.cloudPointTestWay
									? " " + productInformation.cloudPointTestWay
									: ""
							}}
						</div>
					</div>
					<div v-if="productInformation.kbValue" class="dialog-f">
						<div class="dialog-fl">KB Value</div>
						<div class="dialog-fv">{{ productInformation.kbValue }}</div>
					</div>
					<div v-if="productInformation.hlbValue" class="dialog-f">
						<div class="dialog-fl">HLB Value</div>
						<div class="dialog-fv">{{ productInformation.hlbValue }}</div>
					</div>
					<div v-if="productInformation.requiredHLB" class="dialog-f">
						<div class="dialog-fl">Required HLB Value</div>
						<div class="dialog-fv">{{ productInformation.requiredHLB }}</div>
					</div>
					<div v-if="productInformation.foamCharacteristics" class="dialog-f">
						<div class="dialog-fl">Foam Characteristics</div>
						<div class="dialog-fv">{{ productInformation.foamCharacteristics }}</div>
					</div>
					<div v-if="productInformation.acidValue" class="dialog-f">
						<div class="dialog-fl">Acid Value</div>
						<div class="dialog-fv">{{ productInformation.acidValue }}</div>
					</div>
					<div v-if="productInformation.amineValue" class="dialog-f">
						<div class="dialog-fl">Amine Value</div>
						<div class="dialog-fv">{{ productInformation.amineValue }}</div>
					</div>
					<div v-if="productInformation.anilinePoint" class="dialog-f">
						<div class="dialog-fl">Aniline Point</div>
						<div class="dialog-fv">{{ productInformation.anilinePoint }}</div>
					</div>
					<div v-if="productInformation.freezePoint" class="dialog-f">
						<div class="dialog-fl">Freeze Point</div>
						<div class="dialog-fv">
							{{
								productInformation.freezePointQualifier
									? productInformation.freezePointQualifier
									: ""
							}}{{ productInformation.freezePoint }}
							{{ productInformation.freezePointTo ? " to " + productInformation.freezePointTo : "" }}
						</div>
					</div>
					<div v-if="productInformation.meltPoint" class="dialog-f">
						<div class="dialog-fl">Melt Point</div>
						<div class="dialog-fv">
							{{ productInformation.meltPoint }}
							{{ productInformation.meltPointTo ? " to " + productInformation.meltPointTo : "" }}
						</div>
					</div>
					<div v-if="productInformation.softeningPoint" class="dialog-f">
						<div class="dialog-fl">Softening Point</div>
						<div class="dialog-fv">
							{{ productInformation.softeningPoint }}
							{{
								productInformation.softeningPointTo
									? " to " + productInformation.softeningPointTo
									: ""
							}}
						</div>
					</div>
					<div v-if="productInformation.molecularWeight" class="dialog-f">
						<div class="dialog-fl">Molecular Weight</div>
						<div class="dialog-fv">{{ productInformation.molecularWeight }}</div>
					</div>
					<div v-if="productInformation.ph" class="dialog-f">
						<div class="dialog-fl">pH</div>
						<div class="dialog-fv">{{ productInformation.ph }}</div>
					</div>
					<div v-if="productInformation.saponificationValue" class="dialog-f">
						<div class="dialog-fl">Saponification Value</div>
						<div class="dialog-fv">{{ productInformation.saponificationValue }}</div>
					</div>
					<div v-if="productInformation.surfaceTension" class="dialog-f">
						<div class="dialog-fl">Surface Tension</div>
						<div class="dialog-fv">{{ productInformation.surfaceTension }}</div>
					</div>
					<div v-if="productInformation.refractiveIndex" class="dialog-f">
						<div class="dialog-fl">Refractive Index</div>
						<div class="dialog-fv">{{ productInformation.refractiveIndex }}</div>
					</div>
					<div v-if="productInformation.dryBrightness" class="dialog-f">
						<div class="dialog-fl">Dry Brightness</div>
						<div class="dialog-fv">{{ productInformation.dryBrightness }}</div>
					</div>
					<div v-if="productInformation.medianParticleSize" class="dialog-f">
						<div class="dialog-fl">Median Particle Size</div>
						<div class="dialog-fv">{{ productInformation.medianParticleSize }} μm</div>
					</div>
					<div v-if="productInformation.surfaceArea" class="dialog-f">
						<div class="dialog-fl">Surface Area</div>
						<div class="dialog-fv">{{ productInformation.surfaceArea }} m2/g</div>
					</div>
					<div v-if="productInformation.pourPoint" class="dialog-f">
						<div class="dialog-fl">Pour Point</div>
						<div class="dialog-fv">
							{{ productInformation.pourPointQualifier ? productInformation.pourPointQualifier : ""
							}}{{ productInformation.pourPoint }}
							{{ productInformation.pourPointTo ? " to " + productInformation.pourPointTo : "" }}
						</div>
					</div>
					<div v-if="productInformation.activeOxygen" class="dialog-f">
						<div class="dialog-fl">Active Oxygen Content</div>
						<div class="dialog-fv">
							{{ productInformation.activeOxygen }}%
							{{
								productInformation.activeOxygenTo
									? " to " + `${productInformation.activeOxygenTo}%`
									: ""
							}}
						</div>
					</div>
					<div v-if="productInformation.sadt" class="dialog-f">
						<div class="dialog-fl">SADT</div>
						<div class="dialog-fv">{{ productInformation.sadt }}</div>
					</div>
					<div v-if="productInformation.tiO2ContentPercent" class="dialog-f">
						<div class="dialog-fl">TiO2 Content</div>
						<div class="dialog-fv">{{ productInformation.tiO2ContentPercent }} %</div>
					</div>
					<div v-if="productInformation.hydroxylValue" class="dialog-f">
						<div class="dialog-fl">Hydroxyl Value</div>
						<div class="dialog-fv">{{ productInformation.hydroxylValue }}</div>
					</div>
					<div v-if="productInformation.iodineValue" class="dialog-f">
						<div class="dialog-fl">Iodine Value</div>
						<div class="dialog-fv">{{ productInformation.iodineValue }}</div>
					</div>
					<div v-if="productInformation.lowOneFourDioxaneValue" class="dialog-f">
						<div class="dialog-fl">1, 4 Dioxane</div>
						<div class="dialog-fv">{{ productInformation.lowOneFourDioxaneValue }} ppm</div>
					</div>
					<div v-if="productInformation.lowOneFourDioxane" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Low 1,4 Dioxane</div>
					</div>
					<div v-if="productInformation.usdaBiopreferred" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">
							USDA Bio Preferred
							{{
								productInformation.usdaBiopreferredPercent
									? productInformation.usdaBiopreferredPercent + " %"
									: ""
							}}
						</div>
					</div>
					<div v-if="productInformation.hap" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">HAP</div>
					</div>
					<div v-if="productInformation.nonHap" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Non HAP</div>
					</div>
					<div v-if="productInformation.epaRegistered || productInformation.epaNumber" class="dialog-f">
						<div class="dialog-fl">
							{{ productInformation.epaRegistered ? "EPA Registered" : "" }}
						</div>
						<div class="dialog-fv">
							{{ productInformation.epaNumber ? "EPA # " + productInformation.epaNumber : "Yes" }}
						</div>
					</div>
					<div
						v-if="productInformation.reachApproved || productInformation.reachNumber"
						class="dialog-f"
					>
						<div class="dialog-fl">
							{{ productInformation.reachApproved ? "REACH Registered" : "" }}
						</div>
						<div class="dialog-fv">
							{{
								productInformation.reachNumber
									? "REACH # " + productInformation.reachNumber
									: "Yes"
							}}
						</div>
					</div>
					<div v-if="productInformation.gras" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">GRAS</div>
					</div>
					<div v-if="productInformation.kosher" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Kosher</div>
					</div>
					<div v-if="productInformation.cosmos" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">COSMOS</div>
					</div>
					<div v-if="productInformation.massBalance" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Mass Balance</div>
					</div>
					<div v-if="productInformation.eccert" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">ECOCERT</div>
					</div>
					<div v-if="productInformation.biodegradable" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Biodegradable</div>
					</div>
					<div v-if="productInformation.carbCompliant" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">CARB Compliant</div>
					</div>
					<div v-if="productInformation.nonCombustible" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Non Combustible</div>
					</div>
					<div v-if="productInformation.nonFlammable" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Non Flammable</div>
					</div>
					<div v-if="productInformation.nonGMO" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Non GMO</div>
					</div>
					<div v-if="productInformation.foodContactSubstance" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Food Contact Substance</div>
					</div>
					<div v-if="productInformation.foodPackagingSubstance" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">Food Packaging Substance</div>
					</div>
					<div v-if="productInformation.oneFourDioxaneFree" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">1,4-Dioxane Free</div>
					</div>
					<div v-if="productInformation.functionality" class="dialog-f">
						<div class="dialog-fl">Functionality</div>
						<div class="dialog-fv">{{ productInformation.functionality }}</div>
					</div>
					<div v-if="productInformation.percentSolids" class="dialog-f">
						<div class="dialog-fl">% Solids</div>
						<div class="dialog-fv">{{ productInformation.percentSolids }}</div>
					</div>
					<div v-if="productInformation.oilAbsorption" class="dialog-f">
						<div class="dialog-fl">Oil Absorption</div>
						<div class="dialog-fv">{{ productInformation.oilAbsorption }}</div>
					</div>
					<div v-if="productInformation.epoxideEquivalentWeight" class="dialog-f">
						<div class="dialog-fl">Epoxide Equivalent Weight</div>
						<div class="dialog-fv">{{ productInformation.epoxideEquivalentWeight }}</div>
					</div>
					<div v-if="productInformation.peroxideValue" class="dialog-f">
						<div class="dialog-fl">Peroxide Value</div>
						<div class="dialog-fv">{{ productInformation.peroxideValue }}</div>
					</div>
					<div v-if="productInformation.viscosityIndex" class="dialog-f">
						<div class="dialog-fl">Viscosity Index</div>
						<div class="dialog-fv">{{ productInformation.viscosityIndex }}</div>
					</div>
					<div v-if="productInformation.femaNumber" class="dialog-f">
						<div class="dialog-fl">FEMA Number</div>
						<div class="dialog-fv">{{ productInformation.femaNumber }}</div>
					</div>
					<div v-if="productInformation.ohNumber" class="dialog-f">
						<div class="dialog-fl">OH Number</div>
						<div class="dialog-fv">{{ productInformation.ohNumber }}</div>
					</div>
					<div v-if="productInformation.vocExempt" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">VOC Exempt</div>
					</div>
					<div v-if="productInformation.productComments" class="dialog-f">
						<div class="dialog-fl"></div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in productInformation.productComments" :key="i">
									{{ text }}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div v-show="activeTab == 2" id="features-benefits" class="modal-content-details">
					<p v-for="(text, i) in featuresBenefits" :key="i" class="default">
						<template v-if="isEditorContent">
							<RichText :textContent="text" :interactive="false" />
						</template>
						<span v-else style="color: #000000; font-size: 8pt; font-family: MS Sans Serif">
							{{ text }}
						</span>
					</p>
				</div>
				<div v-show="activeTab == 3" id="product-uses" class="modal-content-details">
					<div v-for="(text, i) in uses" :key="i">{{ text }}</div>
				</div>
				<div v-show="activeTab == 4" class="modal-content-details">
					<p v-for="(data, i) in formulations" :key="i">
						<a v-if="data.link" target="_blank" :href="getDocumentLink(data.link, data.dropboxId)">
							{{ data.title }}
						</a>
					</p>
				</div>
				<div v-show="activeTab == 5" class="modal-content-details">
					<form>
						<div class="f">
							<div class="fl">Product Name</div>
							<div class="fv">{{ requestSample.productName }}</div>
						</div>
						<div class="f">
							<div class="fl"><label for="txtSample">Vendor</label></div>
							<div class="fv">{{ requestSample.vendorName }}</div>
						</div>
						<div class="f">
							<div class="fl"><label for="txtSample"></label></div>
							<div class="fv">
								<button
									type="button"
									class="modal-content-details-btn"
									v-if="productInformation.requestSampleLink"
									v-on:click="requestSampleLink(productInformation.requestSampleLink)"
								>
									Start Sample Request
								</button>
							</div>
						</div>
					</form>
				</div>
				<div v-show="activeTab == 6" id="bill-of-lading" class="modal-content-details">
					<div
						v-if="productInformation.package && productInformation.package.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl">Package</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(pkg, i) in productInformation.package" :key="i">{{ pkg }}</li>
							</ul>
						</div>
					</div>
					<div
						v-if="productInformation.locations && productInformation.locations.length > 0"
						class="dialog-f"
					>
						<div class="dialog-fl">Plant Locations</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(loc, i) in productInformation.locations" :key="i">{{ loc }}</li>
							</ul>
						</div>
					</div>
					<div v-if="billOfLading.unNumber" class="dialog-f">
						<div class="dialog-fl">UN#</div>
						<div class="dialog-fv">{{ billOfLading.unNumber }}</div>
					</div>
					<div v-if="billOfLading.hazards" class="dialog-f">
						<div class="dialog-fl">Hazard Classification</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in billOfLading.hazards" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="billOfLading.properShippingNames" class="dialog-f">
						<div class="dialog-fl">Proper Shipping Names</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-for="(text, i) in billOfLading.properShippingNames" :key="i">{{ text }}</li>
							</ul>
						</div>
					</div>
					<div v-if="billOfLading.pgNumber" class="dialog-f">
						<div class="dialog-fl">PG #</div>
						<div class="dialog-fv">{{ billOfLading.pgNumber }}</div>
					</div>
					<div v-if="billOfLading.ergNumber" class="dialog-f">
						<div class="dialog-fl">ERG</div>
						<div class="dialog-fv">{{ billOfLading.ergNumber }}</div>
					</div>
					<div v-if="billOfLading.rqNumber" class="dialog-f">
						<div class="dialog-fl">RQ</div>
						<div class="dialog-fv">{{ billOfLading.rqNumber }}</div>
					</div>
					<div v-if="billOfLading.hmisRating" class="dialog-f">
						<div class="dialog-fl">HMIS Rating</div>
						<div class="dialog-fv">{{ billOfLading.hmisRating }}</div>
					</div>
					<div v-if="billOfLading.nfpaRating" class="dialog-f">
						<div class="dialog-fl">NFPA Rating</div>
						<div class="dialog-fv">{{ billOfLading.nfpaRating }}</div>
					</div>
					<div
						v-if="
							billOfLading.notRegulatedAsDangerous ||
							billOfLading.prop65Exempt ||
							billOfLading.marinePollutantNo ||
							billOfLading.marinePollutantYes
						"
						class="dialog-f"
					>
						<div class="dialog-fl">Bill of Lading</div>
						<div class="dialog-fv">
							<ul class="values">
								<li v-if="billOfLading.notRegulatedAsDangerous" key="nrad">
									Not Regulated as Dangerous
								</li>
								<li v-if="billOfLading.prop65Exempt" key="nrad">Prop 65 Exempt</li>
								<li v-if="billOfLading.marinePollutantYes" key="nrad">Marine Pollutant</li>
								<li v-if="billOfLading.marinePollutantNo" key="nrad">Not a Marine Pollutant</li>
							</ul>
						</div>
					</div>
					<div
						v-if="
							billOfLading.ghsImage1 ||
							billOfLading.ghsImage2 ||
							billOfLading.ghsImage3 ||
							billOfLading.ghsImage4 ||
							billOfLading.ghsImage5
						"
						class="dialog-f"
					>
						<div class="dialog-fl">GHS</div>
						<div class="dialog-fv">
							<div class="ghs">
								<p class="ghs" v-show="billOfLading.ghsImage1">
									<img class="ghs" :src="getGHSImage(billOfLading.ghsImage1)" />
									<span class="ghs">{{ billOfLading.ghsImage1 }}</span>
								</p>
								<p class="ghs" v-show="billOfLading.ghsImage2">
									<img class="ghs" :src="getGHSImage(billOfLading.ghsImage2)" />
									<span class="ghs">{{ billOfLading.ghsImage2 }}</span>
								</p>
								<p class="ghs" v-show="billOfLading.ghsImage3">
									<img class="ghs" :src="getGHSImage(billOfLading.ghsImage3)" />
									<span class="ghs">{{ billOfLading.ghsImage3 }}</span>
								</p>
								<p class="ghs" v-show="billOfLading.ghsImage4">
									<img class="ghs" :src="getGHSImage(billOfLading.ghsImage4)" />
									<span class="ghs">{{ billOfLading.ghsImage4 }}</span>
								</p>
								<p class="ghs" v-show="billOfLading.ghsImage5">
									<img class="ghs" :src="getGHSImage(billOfLading.ghsImage5)" />
									<span class="ghs">{{ billOfLading.ghsImage5 }}</span>
								</p>
							</div>
						</div>
					</div>
					<p>*Please always refer to manufacturer's safety data sheet</p>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" class="modal-footer-action" @click="printProduct()">Print</button>
				<button type="button" class="modal-footer-action" @click="$emit('close')">Cancel</button>
			</div>
		</div>

		<ProductPrint
			v-show="false"
			ref="printData"
			:vendorLogo="vendorLogo"
			:productInfo="printData.productInfo"
			:billOfLading="printData.billOfLading"
			:featuresBenefits="printData.featuresBenefits"
			:productUses="printData.productUses"
		/>
	</div>
</template>

<script>
import RichText from "@/components/RichText.vue";
import ProductPrint from "@/components/ProductSearch/ProductPrint.vue";
export default {
	name: "ResultsModal",
	props: {
		productId: {
			type: Number,
		},
		vendorLogo: {
			type: String,
			default: "",
		},
	},
	components: {
		RichText,
		ProductPrint,
	},
	data() {
		return {
			activeTab: 1,
			productInformation: {
				productName: "",
				remarks: "",
				vendor: "",
				productAttachments: [],
				crossReferenceDescription1: "",
				crossReference1: "",
				crossReferenceDescription2: "",
				crossReference2: "",
				crossReferences: [],
				package: [],
				synonyms: [],
				productClasses: [],
				casData: [],
				inci: "",
				appearance: "",
				solubilities: [],
				specificGravity: null,
				poundsPerGallon: null,
				ph: "",
				numberConsumerRegisteredProducts: "",
				flashPoint: "",
				flashPointQualifier: "",
				pourPoint: "",
				hlbValue: "",
				foamCharacteristics: "",
				acidValue: "",
				freezePoint: "",
				cloudPoint: "",
				boilingPointMin: "",
				meltPoint: "",
				saponificationValue: "",
				hydroxylValue: "",
				scil: false,
				scs: false,
				nonHap: false,
				hap: false,
				vocExempt: false,
				carbCompliant: false,
				prop65Exempt: false,
				reachApproved: false,
				kosher: false,
				foodContactSubstance: false,
				foodPackagingSubstance: false,
				productComments: [],
				unCode: "",
				nfpArating: "",
				nonGMO: false,
				eccert: false,
				gras: false,
				marinePollutantYes: false,
				marinePollutantNo: false,
				notRegulatedAsDangerous: false,
				spreadsheet: "",
				uses: [],
				formulations: [],
				preservatives: [],
				molecularWeight: "",
			},
			featuresBenefits: [],
			uses: [],
			formulations: [],
			requestSample: {},
			billOfLading: {},
			printData: {
				productInfo: null,
				billOfLading: null,
				productUses: null,
				featuresBenefits: null,
			},
			isMobile: false,
		};
	},
	methods: {
		async getProductDetails() {
			this.loadingData = true;

			try {
				const res = await this.$http.post(`/api/search/product-detail`, {
					productId: this.productId,
				});
				console.log(res);
				res.data.productDetails.productInformation
					? (this.productInformation = res.data.productDetails.productInformation)
					: null;
				res.data.productDetails.featuresBenefits
					? (this.featuresBenefits = res.data.productDetails.featuresBenefits)
					: null;
				res.data.productDetails.uses ? (this.uses = res.data.productDetails.uses) : null;
				res.data.productDetails.formulations
					? (this.formulations = res.data.productDetails.formulations)
					: null;
				res.data.productDetails.requestSample
					? (this.requestSample = res.data.productDetails.requestSample)
					: null;
				res.data.productDetails.billOfLading
					? (this.billOfLading = res.data.productDetails.billOfLading)
					: null;
			} catch (error) {
				console.log(error);
			}
		},
		getDocumentLink(fileLink, dropboxId) {
			if (fileLink) {
				let isFilePath = fileLink.startsWith("/");
				let parts = fileLink.split(".");
				let extension = parts.pop();
				let isPdf = extension.includes("pdf");
				let hasSpecifier = fileLink.includes("//");
				let uri = hasSpecifier ? fileLink : "//" + fileLink;
				return isFilePath
					? `/api/storage/${isPdf ? "preview" : "download"}-file?filePath=` +
							encodeURIComponent(fileLink) +
							"&dropboxId=" +
							dropboxId
					: uri;
			}
		},
		getGHSImage(ghs) {
			let imageSrc = this.$constants.Hazards.find((hazard) => hazard.GHS === ghs);
			return imageSrc ? imageSrc.Asset : "";
		},
		getPrintData() {
			this.printData.productInfo = document.getElementById("product-information").innerHTML;
			this.printData.billOfLading = document.getElementById("bill-of-lading").innerHTML;
			this.printData.featuresBenefits = document.getElementById("features-benefits").innerHTML;
			this.printData.productUses = document.getElementById("product-uses").innerHTML;
		},
		printProduct() {
			this.getPrintData();
			this.$nextTick(() => this.$refs.printData.print());
		},
		requestSampleLink(link) {
			this.$emit("requestSample");
			if (link) {
				window.open(link, "_blank");
			}
		},
		nav(crossReferenceId) {
			return `/cross-reference?crossReferenceId=${crossReferenceId}`;
		},
	},
	computed: {
		isEditorContent() {
			return (
				this.featuresBenefits &&
				this.featuresBenefits.length === 1 &&
				this.featuresBenefits[0] &&
				this.featuresBenefits[0].startsWith("<")
			);
		},
		isHorizontalOrientation() {
			return window.matchMedia("(orientation: landscape)").matches;
		},
		filteredCrossReferences() {
			return this.productInformation.crossReferences
				? this.productInformation.crossReferences.filter((cr) => {
						return cr.state === "published";
				  })
				: [];
		},
	},
	mounted() {
		this.getProductDetails();
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
	},
};
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	// border: 1px solid #a6c9e2;
	border: 1px solid #1a1a1a;
	border-radius: 5px;
	// background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
	color: #222;
	z-index: 101;
	overflow: hidden;

	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#aaa, 0.3);
		z-index: 100;
	}

	&-title-bar {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		// border: 1px solid #4297d7;
		border: 1px solid #1a1a1a;
		// background: #5c9ccc url("~@/assets/ui-bg_gloss-wave_55_5c9ccc_500x100.png") 50% 50% repeat-x;
		background: #fff;
		// color: #fff;
		color: #1a1a1a;
		font-weight: bold;

		span {
			white-space: nowrap;
			margin-left: 1rem;
		}

		button {
			display: inline-block;
			margin-left: auto;
			width: 20px;
			height: 20px;
			padding: 1px;
			// border: 1px solid #c5dbec;
			// border: 1px solid #1a1a1a;
			// border-radius: 5px;
			// background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			font-weight: bold;
			// color: #2e6e9e;
			color: #1a1a1a;

			i {
				font-size: 1.6rem;
				// color: #6da8d5;
				color: #1a1a1a;
			}
		}
	}

	&-content {
		position: relative;
		background-color: $color-white;
		padding: 0.5rem 1rem;
		height: 520px; //match result modal height from chemformation of 520px
		overflow: auto;
		font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
		line-height: 1.4;

		#vendorLogo {
			right: 1rem;
		}

		&-nav {
			display: flex;
			flex-flow: row wrap;
			width: 100%;
			padding: 0 1px;
			// border-bottom: 1px solid #4297d7;
			border-bottom: 1px solid #1a1a1a;

			&-tab {
				// background-color: #dfeffc;
				// border: 1px solid #c5dbec;
				border: 1px solid #1a1a1a;
				// border-bottom: 1px solid #4297d7;
				border-bottom: 1px solid #1a1a1a;
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				margin: 0 1px;
				margin-bottom: -1px;
				padding: 0.5rem 1rem;
				// color: #2e6e9e;
				color: #1a1a1a;
				font-weight: 700;
				font-size: 10px;

				&-active {
					background-color: $color-white;
					// border: 1px solid #79b7e7;
					border: 1px solid #1a1a1a;
					border-bottom: 1px solid $color-white;
					color: #ba0c2f;
				}
			}
		}

		&-details {
			display: block;
			padding: 1rem 0.8rem;

			.dialog-fv {
				ul {
					padding-left: initial;
				}
			}

			p {
				margin: 1rem 0;
			}

			&-btn {
				display: inline-block;
				padding: 4px 11px;
				// border: 1px solid #c5dbec;
				border: 1px solid #1a1a1a;
				border-radius: 5px;
				// background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
				// color: #2e6e9e;
				color: #1a1a1a;
				font-size: 1.1rem;
				font-weight: 700;
			}
		}
	}

	&-footer {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-end;
		padding: 0.5rem 1rem;
		// border-top: 1px solid #c5dbec;
		border-top: 1px solid #1a1a1a;
		background: #fff;

		&-action {
			display: inline-block;
			margin-left: 0.5rem;
			// border: 1px solid #a6c9e2;
			border: 1px solid #1a1a1a;
			border-radius: 5px;
			// background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			// color: #2e6e9e;
			color: #1a1a1a;
			font-size: 1rem;
			padding: 0.1rem 0.8rem;
		}
	}
}

#results-modal {
	// Prevent Copy-Paste
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
</style>
